import cookies from 'next-cookies'
import moment from 'moment'
import { MAIN_CONFIG } from '../../config/main';
import { BREAKPOINTS, DESKTOP_WIDTH } from './Breakpoints';
import { useEffect, useState } from 'react';


/**
 * Formats given string with comma separated tags into a JS array
 * @param tags
 * @returns {*|string[]|*[]}
 */
export const formatTags = (tags) => (tags) ? tags.split(";") : [];

export const handleResize = (stateHandler, value) => stateHandler(value);

/**
 * parse given string with as an html
 * @param string
 */
export const parseHtml = (string = '') =>  <div dangerouslySetInnerHTML={{ __html: string }} />

// export const formatDeadlineDate = (date) => {
// 	if(date) {
// 		return `${date.toDateString()}, ${getTime(date)}`
// 	}
// 	return date;
// }

export const formatDeadlineDate = (inputDate) => {
	// Define the month names and day names
	const monthNames = ["January", "February", "March", "April", "May", "June", "July","August", "September", "October", "November", "December"];
	const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  
	// Get the individual components of the date
	const year = inputDate.getFullYear();
	const month = inputDate.getMonth();
	const day = inputDate.getDate();
	const hours = inputDate.getHours();
	const minutes = inputDate.getMinutes();
	const ampm = hours >= 12 ? "PM" : "AM";
  
	// Convert hours to 12-hour format (fix for 12 PM)
	const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  
	// Format the date string
	const formattedDate = `${dayNames[inputDate.getDay()]}. ${monthNames[month]} ${day.toString().padStart(2, "0")}, ${year}, ${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
	return formattedDate;
  }

export const formatDeadlineDateWithoutTime = (date) => {
	if(date) {
		return `${date.toDateString()}`
	}
	return date;
}

export const formatOpenDate = (date) => {
	if(date) {
		return `${date.toDateString()}`
	}
	return date;
}

export const getTime = (date) => {
	const now = date.toLocaleTimeString([], {
	  hour: '2-digit',
	  minute: '2-digit',
	  hour12: true
	});
	return now
}

/**
 * Format question/step-config answer options
 * @param string
 * return []
 */
export const formatAnswerOptions = (string = '', dataType = 'text') => {
	let options = [];
	string = (['Radio Button', 'Checkbox'].indexOf(dataType) > -1 && !string) ? 'true;false' : string
	string.split(';').map(val => {
		options.push({label: val, value: val})
		return null
	})
	return options;
}

/**
 * Format question/comfig answer
 * @param string
 * return []/string
 */
export const getAriaLabel =(label='')=>{
	if(typeof label ==='string'){
		return label
	}
	else if (typeof label==='object'){
		return label?.props?.dangerouslySetInnerHTML?.__html || label
	}

}

const formattedTextAnswer = (value) => {
	return value
}

const formattedCheckAnswer = (value) => {
	return value.split(';')
}

const formattedDropdownAnswer = (value) => {
	return {
		label: value,
		value
	}
}

const formattedMultiSelectAnswer = (value, symbol, answerOptions) => {
	let formatedValues = value.split(symbol)
	if (answerOptions.length > 0) {
		formatedValues = formatedValues.filter(option => {
			return answerOptions.includes(option)
		})
	}
	return value ? formatedValues.map(option => {
		return {
			label: option,
			value: option
		}
	})
		: ''
}

export const formattedAnswer = (value = '', type = 'text', symbol = ';', answerOptions = []) => {
	value = value === null ? '' : value
	let singleSelect = ['Dropdown', "Picklist"]
	if (type === 'Multiselect Picklist') {
		return formattedMultiSelectAnswer(value, symbol, answerOptions)
	}
	else if (type === 'Checkbox') {
		return formattedCheckAnswer(value)
	}
	else if (singleSelect.includes(type)) {
		return formattedDropdownAnswer(value)
	} else {
		return formattedTextAnswer(value)
	}
}

// MM/DD/YYYY
var date_regex_slash = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)\d{2})$/

// DD/MM/YYYY
var date_month_regex_slash = /^((0?[1-9]|[12][0-9]|3[01])[- /.](0?[1-9]|1[012])[- /.](19|20)\d{2})$/

// YYYY-MM-DD
var date_regex_dash = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/;

// DD_MM_YYYY
var date_regex_format_date = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{4}$/;

// MM/YYYY
var date_month_year_regex = /^((0?[1-9]|1[012])[- /.](19|20)\d{2})$/

// YYYY
var date_year_regex = /^(19|20)\d{2}$/;


export const convertDataFormat = (dateStr = '', type = 'Date', includeYear = true) => {
	if( dateStr && (date_regex_slash.test(dateStr)) ) {
		return dateStr.replace(date_regex_slash, (match, monthStr, dayStr, yearStr) => {
		  let date = match.split('/');
		  let yearFormatted = '';
		  if (includeYear) yearFormatted = `/${date[2]}`
		  return (type === 'Date(Date-Month-Year)') ? `${date[1]}/${date[0]}${yearFormatted}` : `${date[0]}/${date[1]}${yearFormatted}`
		});

	  } else if( (date_month_regex_slash.test(dateStr)) ) {
		return dateStr.replace(date_month_regex_slash, (match, dateStr, monthStr, yearStr) => {
		  let date = match.split('/')
		  let yearFormatted = '';
		  if (includeYear) yearFormatted = `/${date[2]}`;
		  return (type === 'Date(Date-Month-Year)') ? `${date[0]}/${date[1]}${yearFormatted}` : `${date[1]}/${date[0]}${yearFormatted}`
		});
	  }
	  else if( (date_regex_dash.test(dateStr)) ) {
		return dateStr.replace(date_regex_dash, (match, yearStr, monthStr, dayStr) => {
		  let date = match.split('-')
		  let yearFormatted = '';
		  if (includeYear) yearFormatted = `/${date[0]}`;
		  return (type === 'Date(Date-Month-Year)') ? `${date[2]}/${date[1]}${yearFormatted}` : `${date[1]}/${date[2]}${yearFormatted}`

		});
	  } else if ( (date_regex_format_date.test(dateStr))){
		  return dateStr.replace(date_regex_format_date, (match, monthStr, dayStr, yearStr) => {
			let date = match.split('/');
			return (type === 'Date(Date-Month-Year)') ? `${date[0]}-${date[1]}-${date[2]}` : `${date[1]}-${date[0]}-${date[2]}`;
		  });
	  }

	  else {
		return dateStr;
	  }
}

export const checkDateFormats = (value = '', type = 'Date') => {
	let valid;

	switch(type) {
		case 'Date(Date-Month-Year)':
			// value = convertDataFormat(value, type)
			valid = date_month_regex_slash.test(value)
			break;
		case 'Date':
		case 'Date(Month-Date-Year)':
			// value = convertDataFormat(value, type)
			valid = date_regex_slash.test(value)
			break;
		case 'Date(Month-Year)':
			valid = date_month_year_regex.test(value)
			break;
		case 'Date(Year)':
			valid = date_year_regex.test(value)
			break;
		default:
			// value = convertDataFormat(value, type)
			valid = date_regex_slash.test(value)
	}

	return valid
}

/**
 * field visibility
 * @param string
 * return []/string
 */
export const isVisible = (data, field) => {
    return (data || []).includes(field)
}

/**
 * Format field radio
 * @param string
 * return []/string
 */
export const onRadio = (field) => {
    if(field  === true || field  === "Yes"){
  		return {label: 'Yes', value: 'Yes'}
  	}else if(field  === false || field  === "No"){
  		return {label: 'No', value: 'No'}
  	}else{
  		return {}
    }
}

/**
 * Format field label and value
 * @param string
 * return []/string
 */
export const formatLabelANDValue = (data) => {
	if(data){
		return {label: data, value: data}
	}
	return {}
}

export const formatCurrency = (currency = '',currencyName = '') => {
	currency = currency ? currency : ''
	let decimal = currency.toString().split('.');
	var numberPattern = /\d+/g;
	const isCurrencyNegative = decimal[0]?.startsWith('-');
	currency =  ((decimal[0] || "").toString().match(numberPattern) || []).join('')
	var decimalFilter =  decimal.length > 1 ? ((decimal[1] || "").toString().match(numberPattern) || []).join('') : ''
	currency = (decimal.length > 1) ? `${currency}.${decimalFilter}` : currency
	if (currencyName && currencyName !== "USD") {
		// Return the numeric value as-is without formatting
		return currency;
	}
	const getNegativeSign = isCurrencyNegative ? '-' : '';
	const finalCurrency = currency ? `${getNegativeSign}${currency.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}` : '';	
	return finalCurrency;
}

const handleCheckMarketPlace = (role, marketPlace, roleContext) => {
	if (role === 'APPLICANT' && marketPlace) {
		return '/applicant'
	} else if (role === 'REVIEWER') {
		return MAIN_CONFIG.ELEMENTS_URLS.REVIEWER.DASHBOARD
	} else if (roleContext) {
		return roleContext
	} else {
		return '/'
	}
}

export const checkMarketPlace = (isGeneralScholorship=false) => {
	var role = cookies('context').context
	const { USER_ROLE_CONTEXT } = MAIN_CONFIG;
	const roleContext = USER_ROLE_CONTEXT.find(i => i.context === role);
	var marketPlace = cookies('context').marketPlace
	return handleCheckMarketPlace(role, marketPlace, roleContext?.link)

	// return (role === 'APPLICANT' && marketPlace && isGeneralScholorship) ? `/` : (role === 'APPLICANT' && marketPlace) ? `/applicant/applications` : role === 'REVIEWER' ? '/reviewer-portal/programs' : (role === 'APPLICANT' && isGeneralScholorship) ? `/` : role === 'APPLICANT' ? `/applicant/applications` : roleContext?.link || '/'
}

export const findHomeURL = () => {
	var role = cookies('context').context
	const { USER_ROLE_CONTEXT } = MAIN_CONFIG;
	const roleContext = USER_ROLE_CONTEXT.find(i => i.context === role);
	return roleContext.link || '/'
}

export const builderTooltipFunc = (builderTooltips, field, type) => {
    let tooltipDesc = ''
    if(builderTooltips.length > 0){
        let tooltip = builderTooltips.filter(r => (r.field_name === field && r.object_name === type))
        tooltipDesc = tooltip.length > 0 ? tooltip[0].description : ''
    }
    return tooltipDesc
}


export const chartDataFilter = (chartData, type) => {
	const colorCode = ['#FF6384','#DAF7A6','#E9967A','#008000','#00FF00','#CD5C5C','#808080','#FFCE56','#20B2AA','#FA8072','#36A2EB','#000000', '#76EE00','#66CD00','#458B00','#ADFF2F','#CAFF70','#BCEE68','#A2CD5A','#6E8B3D','#556B2F','#6B8E23','#C0FF3E','#B3EE3A','#9ACD32','#698B22','#FFFFF0','#EEEEE0','#CDCDC1','#8B8B83','#F5F5DC','#FFFFE0','#EEEED1','#CDCDB4','#8B8B7A','#FAFAD2','#FFFF00','#EEEE00','#CDCD00','#8B8B00','#808069','#808000','#BDB76B','#FFF68F','#EEE685','#CDC673','#8B864E','#F0E68C','#EEE8AA','#FFFACD','#009ACD','#00688B','#33A1C9','#ADD8E6','#BFEFFF','#B2DFEE','#9AC0CD','#68838B','#B0E0E6','#98F5FF','#8EE5EE','#7AC5CD','#53868B','#00F5FF','#00E5EE','#00C5CD','#00868B','#5F9EA0','#00CED1','#F0FFFF','#E0EEEE','#C1CDCD','#838B8B','#00B2EE','#009ACD','#00688B','#33A1C9','#ADD8E6','#BFEFFF','#B2DFEE','#9AC0CD','#68838B','#B0E0E6','#98F5FF','#8EE5EE','#7AC5CD','#53868B','#00F5FF','#00E5EE','#00C5CD','#00868B','#5F9EA0','#00CED1','#F0FFFF','#E0EEEE','#C1CDCD','#838B8B','#E0FFFF','#D1EEEE','#B4CDCD','#7A8B8B','#BBFFFF','#AEEEEE'];
	var Arraylabel =[];
    var ArrayValue= [];
    for (var keyBrowserName in chartData) {
		if (chartData.hasOwnProperty(keyBrowserName) && keyBrowserName === type)  {
	       const  deviceType = (chartData[keyBrowserName]);
	        for(var keyBrowser in deviceType){
	          if (deviceType.hasOwnProperty(keyBrowser)){
	            const deviceValue = (deviceType[keyBrowser]);
	            const deviceKey = keyBrowser;
	            Arraylabel.push(deviceKey);
	            ArrayValue.push(deviceValue);
	          }
	        }
		}
    }

    return {
        labels: Arraylabel,
        datasets: [{
          data: ArrayValue,
          backgroundColor: colorCode,
          hoverBackgroundColor: colorCode
        }]
    };
}


export const isValidURL = (string) => {
	if(string){
  		var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  		return (res !== null)
	}
	return ''
};


export const useResponsiveState = () => {
	const [isMobileScreen, setIsMobileScreen] = useState(false);
	const [isIpadScreen, setIsIpadScreen] = useState(false);
  
	const resize = () => {
	  handleResize(setIsMobileScreen, window.innerWidth < DESKTOP_WIDTH);
	  handleResize(setIsIpadScreen, window.innerWidth > BREAKPOINTS.small && window.innerWidth < BREAKPOINTS.large);
	};
  
	useEffect(() => {
	  resize(); // Set initial state
	  window.addEventListener('resize', resize);
  
	  return () => {
		window.removeEventListener('resize', resize);
	  };
	}, []);
  
	return { isMobileScreen, isIpadScreen };
  };

  export const handleSetBeforeLogoutRoute = () => {
	let currentPath = window?.location?.pathname
	sessionStorage.setItem('previousPath', currentPath)
  }

  export const handleRediretInNewTab = (url) => window.open(url)

  export const handleRedirectHrefUrl = (url) => window.location.href = url;

  export const validateEmailRegex = email => {
    const regex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][\w.%+\-!`'~#$^*/?]*|"[a-zA-Z0-9._%+\-!`'~#$^*/?]+")@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       return regex.exec(email) !== null; // Returns true if a match is found, otherwise false
  };

  export const renderQuestionLabelWithAsterisk = (question) => {
	const {label__c, required__c, doc_upload_required__c, data_type__c} = question
	const isRequired = data_type__c !== 'Output Message'  && (required__c || doc_upload_required__c || (question.minimum_word_required__c && question.word_count_enable__c))
	return parseHtml(isRequired ? `${label__c?? ""} <span style="color:red">*</span>` : label__c);
  }